import React from 'react'
import Head from 'next/head'
import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { includeDefaultNamespaces, useTranslation } from '../i18n'
import LandingPage from '@components/LandingPage'

const Home: React.FC<{ pageVariant?: 'a' | 'b' | null }> = ({ pageVariant }) => {
    const { t } = useTranslation()
    return (
        <>
            <Head>
                <title>{t('landing:tagline.default')}</title>
            </Head>
            <LandingPage version="default" pageVariant={pageVariant} />
        </>
    )
}

export const getServerSideProps: GetServerSideProps = async ({ locale, query }) => {
    const { pageVariant } = query
    return {
        props: {
            ...(await serverSideTranslations(locale, includeDefaultNamespaces(['landing']))),
            pageVariant: pageVariant ?? null,
        },
    }
}

export default Home
